<template>
    <a-layout-content class="Category">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout style="padding: 24px 0; background: #fff">
                <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                    <h2>Thư viện</h2>
                    <div class="upload">
                        <a-upload
                                accept=".jpg,.jpeg,.png"
                                :action="config.uploadEndPoint + '/products/image/form'"
                                name="images"
                                listType="picture-card"
                                :fileList="fileList"
                                :multiple="true"
                                :headers="{'Authorization': `Bearer ${getCredentials.AccessToken}`}"
                                @preview="handlePreview"
                                @change="handleChange"
                        >
                            <div v-if="fileList.length === 0">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">Upload hình ảnh</div>
                            </div>
                        </a-upload>
                        <div v-if="fileList.length > 0">
                            <a-button @click="handleSaveImages" type="primary">Lưu hình ảnh</a-button>
                        </div>
                        <a-modal :centered="true" :width="700" :visible="previewVisible" :footer="null" @cancel="handleCancel">
                            <img alt="example" style="width: 100%" :src="previewImage" />
                        </a-modal>
                    </div>
                    <a-divider/>
                    <div class="gallery-container">
                        <div style="cursor: pointer; display: inline-block; margin: 5px; padding: 5px; border: 1px solid #eee; border-radius: 5px;" v-for="(image, index) in images" :key="index">
                            <span @click="() => handlePreview(image)" :style="`display: inline-block; width: 150px; height: 150px; background-size: cover; background-image: url('${image.url}')`" />
                            <div class="action">
                                <a-icon @click="() => handlePreview(image)" style="margin: 5px" type="eye" />
                                <a-popconfirm
                                        title="Bạn có chắc chắn muốn xóa?"
                                        @confirm="() => handleDeleteImage(image)"
                                        okText="Xóa"
                                        cancelText="Hủy"
                                >
                                    <a-icon type="delete" />
                                </a-popconfirm>
                            </div>
                        </div>
                    </div>
                    <a-pagination :defaultPageSize="this.pageSize" @change="onPaginationChange" v-if="currentPage" style="text-align: right; margin-top: 20px;" :current="currentPage.number + 1" :total="currentPage.totalElements" />
                </a-layout-content>
            </a-layout>

        </div>
    </a-layout-content>
</template>

<script>
    import { RepositoryFactory } from "../../repository/RepositoryFactory";
    import config from "../../configs/ApplicationConfig.js";
    import { mapGetters } from "vuex";
    // eslint-disable-next-line no-unused-vars
    const imageRepository = RepositoryFactory.get('image');

    export default {
        name: 'Gallery',
        data() {
            return {
                previewVisible: false,
                previewImage: '',
                fileList: [],
                images: [],
                config,
                currentPage: null,
                pageNumber: 0,
                pageSize: 30
            }
        },
        async created() {
            let  page = {
                page: this.page,
                size: this.pageSize,
                sort: 'id,desc'
            };
            await this.getImageByPage({page});
        },
        methods: {
            handleCancel() {
                this.previewVisible = false;
            },
            handlePreview(file) {
                this.previewImage = file.url || file.thumbUrl;
                this.previewVisible = true;
            },
            handleChange({ fileList }) {
                this.fileList = fileList;
            },
            handleSaveImages() {
                let images = this.fileList.map( file => {
                    return {
                        name: file.name,
                        url: file.response[0]
                    }
                });
                // eslint-disable-next-line no-console
                this.saveImage({ images })
            },
            async handleDeleteImage(image) {
                // eslint-disable-next-line no-console
                try {
                    let { data } = await imageRepository.deleteImage({ id: image.id });
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    // eslint-disable-next-line no-console
                    this.images = this.images.filter( i => i.id !== image.id);
                } catch (e) {
                    this.openNotification(e.message);
                }

            },
            async onPaginationChange(pageNumber, pageSize) {
                let  page = {
                    page: pageNumber - 1,
                    size: pageSize,
                    sort: 'id,desc'
                };
                await this.getImageByPage({page});
            },
            async saveImage({ images }) {
                try {
                    let { data } = await imageRepository.addImages({ images });
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    // eslint-disable-next-line no-console
                    this.fileList = [];
                    this.images = [...data.data, ...this.images];
                } catch (e) {
                    this.openNotification(e.message);
                }
            },
            async getImageByPage({ page }) {
                try {
                    let { data } = await imageRepository.getAllByPage({
                       page
                    });
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    // eslint-disable-next-line no-console
                    this.currentPage = data.data;
                    this.images = data.data.content;
                } catch (e) {
                    this.openNotification(e.message);
                }
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
        },
        computed: {
            ...mapGetters('authenticate', [
                'getCredentials'
            ])
        }
    };
</script>

<style>
    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .upload {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .gallery-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
</style>
